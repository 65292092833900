import React, { useEffect } from "react";
import Form from "../components/Form";
import Aos from "aos";
import "aos/dist/aos.css";
import "../styles/index.css";
import "../styles/contact.css";
import contactSvg from "../assets/contact.svg";

function Footer() {
  const devSize = {
    height: "55vh",
    width: "auto",
  };

  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  return (
    <div
      id="contact"
      className="flex flex-col justify-center"
      data-aos="zoom-in-up"
      data-aos-duration="1000"
      data-aos-delay="200"
    >
      <div className="header">
        <div className="inner-header lg:h-screen">
          <p className="titles mx-auto my-7">
            <span
              data-aos="fade-down"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              Contact Me
            </span>
            <p
              data-aos="zoom-in-up"
              data-aos-duration="1000"
              className="text-2xl lg:mx-20 mx-8 my-10 font-light"
            >
              Thank you for stopping by. I'd love to hear your feedback. If you
              are in search of someone to help you with software or web
              development projects, I'd be very glad to help you out :D
            </p>
          </p>
          <div className="flex flex-col xl:flex-row lg:px-40">
            <div className="lg:w-1/2 md:w-3/4 mx-auto lg:w-3/4 xs:w-1 lg:mt-10">
              <img
                data-aos="fade-right"
                data-aos-duration="1000"
                id="contactSvg"
                style={devSize}
                src={contactSvg}
                alt=""
              />
            </div>
            <div
              id="myForm"
              className="lg:w-5/6 z-10 mx-8 mb-10"
              data-aos="flip-left"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <Form />
            </div>
          </div>
        </div>
        <div>
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g className="parallax">
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="0"
                fill="rgba(255,255,255,0.7"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="3"
                fill="rgba(255,255,255,0.5)"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="5"
                fill="rgba(255,255,255,0.3)"
              />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
      </div>
      <div className="content flex my-auto mx-auto z-0">
        <a
          href={"https://github.com/bimbisara13"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            id="github"
            className="mx-5 my-9 transform hover:-translate-y-1 hover:scale-105 transition duration-500 ease-in-out"
            style={{ height: "2em", width: "auto" }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
          </svg>
        </a>
        <a href={"https://linkedin.com/in/bimbisara-pinnamaneni-4b5169174"} target="_blank" rel="noopener noreferrer">
          <svg
            id="linkedin"
            className="mx-5 my-9 transform hover:-translate-y-1 hover:scale-105 transition duration-500 ease-in-out"
            style={{ height: "2em", width: "auto" }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
          </svg>
        </a>
        <a
          href={"mailto:bimbisara13@gmail.com"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            id="email"
            className="mx-5 my-9 transform hover:-translate-y-1 hover:scale-105 transition duration-500 ease-in-out"
            style={{ height: "2em", width: "auto" }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z" />
          </svg>
        </a>
        <a
          href={"https://wa.me/+918296932512?text=Hey, I found your contact on your website. I'd like to have a chat with you."}
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            id="whatsapp"
            className="mx-5 my-9 transform hover:-translate-y-1 hover:scale-105 transition duration-500 ease-in-out"
            style={{ height: "2em", width: "auto" }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12.036 5.339c-3.635 0-6.591 2.956-6.593 6.589-.001 1.483.434 2.594 1.164 3.756l-.666 2.432 2.494-.654c1.117.663 2.184 1.061 3.595 1.061 3.632 0 6.591-2.956 6.592-6.59.003-3.641-2.942-6.593-6.586-6.594zm3.876 9.423c-.165.463-.957.885-1.337.942-.341.051-.773.072-1.248-.078-.288-.091-.657-.213-1.129-.417-1.987-.858-3.285-2.859-3.384-2.991-.099-.132-.809-1.074-.809-2.049 0-.975.512-1.454.693-1.653.182-.2.396-.25.528-.25l.38.007c.122.006.285-.046.446.34.165.397.561 1.372.611 1.471.049.099.083.215.016.347-.066.132-.099.215-.198.33l-.297.347c-.099.099-.202.206-.087.404.116.198.513.847 1.102 1.372.757.675 1.395.884 1.593.983.198.099.314.083.429-.05.116-.132.495-.578.627-.777s.264-.165.446-.099 1.156.545 1.354.645c.198.099.33.149.38.231.049.085.049.482-.116.945zm3.088-14.762h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-6.967 19.862c-1.327 0-2.634-.333-3.792-.965l-4.203 1.103 1.125-4.108c-.694-1.202-1.059-2.566-1.058-3.964.002-4.372 3.558-7.928 7.928-7.928 2.121.001 4.112.827 5.609 2.325s2.321 3.491 2.32 5.609c-.002 4.372-3.559 7.928-7.929 7.928z" />
          </svg>
        </a>
      </div>
    </div>
  );
}

export default Footer;
