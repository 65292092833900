import React, { useState, useEffect } from "react";
import PreLoader from "./components/PreLoader";
import ScrollIndicator from "./components/ScrollIndicator";
import Menu from "./components/Menu";
import Home from "./components/Home";
import About from "./components/About";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import SnackBar from "./components/SnackBar";

import "./styles/index.css";

function App() {
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  const [loading, setloading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 3000);
  }, []);
  return loading ? (
    <PreLoader />
  ) : (
    <>
      <SnackBar />
      <ScrollIndicator />
      <Menu />
      <Home />
      <About />
      <Skills />
      <Projects />
      <Contact />
    </>
  );
}

export default App;
