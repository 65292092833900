import React, {useEffect} from "react";
import { motion } from "framer-motion";
import Aos from "aos";
import "aos/dist/aos.css";
import developer from "../assets/developer.svg";
import graduation from "../assets/graduation.png";
import "../styles/index.css";
import "../styles/about.css";

function About() {

  const devSize = {
    height: "65vh",
    width: "auto",
  };

  useEffect(() => {
    Aos.init({duration: 3000});
  }, []);

  return (
    <div id="about" className="lg:px-32 my-auto min-h-screen flex flex-col justify-center lg:ml-15" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-delay="200">
        <p className="titles mx-auto mt-8 xl:mb-9"><span className="title">Who am I?</span></p>
        <div className="flex flex-col xl:flex-row">
          <div className="lg:w-5/6 w-full xs:w-full lg:mt-20" data-aos="zoom-in-right" data-aos-duration="1000" data-aos-delay="400">
            <p id="aboutInfo" className="sm:mx-2">
              I'm a developer based in Bangalore. I enjoy seeing things from a fresh perspective and I'm focused on creating meaningful experiences through design. I’m curious, and I enjoy work that challenges me to learn something new and stretch in a different direction :)           </p>
              <div className="shadow-2xl rounded-lg lg:w-3/4 w-full font-normal mt-16 py-4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
                <motion.img id="graduation" drag dragConstraints={{left: 0, top: 0, right: 0, bottom: 0}} dragElastic={0.1} src={graduation} className="float-right mr-4 mt-4" style={{height:64, width:64}} alt=""/>
                <p className="text-green-600 ml-4 text-xl">Education</p>
                <p className="ml-4 text-xl mt-4">B. Tech in Computer Science and Engineering</p>
              </div>
          </div>
          <div className="lg:mt-0" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="600">
            <img id="developer" style={devSize} src={developer} alt="" />
          </div>
        </div>
    </div>
  );
}

export default About;
