const particlesConfig  = {
    particles: {
        number: {
          value: 80,
          density: {
            enable: true,
            value_area: 1000,
          }
        },
        color: {
          value: '#000000'
      },
      line_linked: {
          enable: true,
          color: "#000000"
      },
      shape       : {
        type    : 'circle',
        stroke  : {
            width : 0,
            color : '#000000'
        },
        polygon : {
            nb_sides : 5
        }
    },
      }
}

export default particlesConfig