import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import App from './App';
import p1 from './components/projects/p1';
import p2 from './components/projects/p2';
import p3 from './components/projects/p3';
import p4 from './components/projects/p4';
import './styles/tailwind.css'

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Route path="/" exact component={App} />
			<Route path="/face-recognition" exact component={p1} />
      <Route path="/expense-approval-system" exact component={p2} />
      <Route path="/steganography" component={p3} />
      <Route path="/consultus" exact component={p4} />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

