import React from "react";
import { motion } from "framer-motion";
import "../styles/menu.css";
import "../styles/index.css";

function Menu() {
  const variants1 = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 4,
        duration: 1.2,
      },
    },
  };

  function gotoHome() {
    var home = document.getElementById("home");
    document.getElementById("menu-icon").checked = false;
    home.scrollIntoView();
  }

  function gotoAbout() {
    var about = document.getElementById("about");
    document.getElementById("menu-icon").checked = false;
    about.scrollIntoView();
  }

  function gotoSkills() {
    var skills = document.getElementById("skills");
    document.getElementById("menu-icon").checked = false;
    skills.scrollIntoView();
  }

  function gotoProjects() {
    var projects = document.getElementById("projects");
    document.getElementById("menu-icon").checked = false;
    projects.scrollIntoView();
  }

  function gotoContact() {
    var contact = document.getElementById("contact");
    document.getElementById("menu-icon").checked = false;
    contact.scrollIntoView();
  }

  return (
    <motion.div initial="hidden" animate="visible" variants={variants1}>
      <input
        className="menu-icon"
        type="checkbox"
        id="menu-icon"
        name="menu-icon"
      />
      <label htmlFor="menu-icon"></label>
      <nav className="nav">
        <ul>
          <li>
            <a
              href={() => false}
              onClick={gotoHome}
              className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
            >
              Home
            </a>
          </li>
          <li>
            <a
              href={() => false}
              onClick={gotoAbout}
              className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
            >
              About
            </a>
          </li>
          <li>
            <a
              href={() => false}
              onClick={gotoSkills}
              className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
            >
              Skills
            </a>
          </li>
          <li>
            <a
              href={() => false}
              onClick={gotoProjects}
              className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
            >
              Projects
            </a>
          </li>
          <li>
            <a
              href={() => false}
              onClick={gotoContact}
              className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
            >
              Contact
            </a>
          </li>
        </ul>
      </nav>
    </motion.div>
  );
}

export default Menu;
