import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "../../styles/p-components.css";
import "../../styles/index.css";

import steganography from "../../assets/steganography.svg";

function p3() {
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  const variants1 = {
    hidden: {
      opacity: 0,
      y: "-100vw",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: "70",
        delay: 0.5,
        duration: 1.5,
      },
    },
  };

  const variants2 = {
    hidden: {
      opacity: 0,
      x: "-100vw",
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeOut",
        stiffness: "70",
        delay: 1.5,
        duration: 1,
      },
    },
  };

  const variants3 = {
    hidden: {
      opacity: 0,
      y: "100vw",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeOut",
        stiffness: "70",
        delay: 2,
        duration: 1,
      },
    },
  };

  const variants4 = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 2.5,
        duration: 2,
      },
    },
  };

  const devSize = {
    height: "65vh",
    width: "auto",
  };

  return (
    <div
      id="p3"
      className="lg:px-40 my-auto flex flex-col text-white min-h-screen"
    >
      <Link to="/">
        <motion.div
          className="fixed backBtn z-10"
          initial="hidden"
          animate="visible"
          variants={variants4}
        >
          <svg
            id="back"
            className="mx-5 my-9 transform hover:-translate-y-1 hover:scale-105 transition duration-500 ease-in-out"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
          </svg>
        </motion.div>
      </Link>
      <motion.p
        className="titles mx-auto font-thin lg:mt-10 mt-20 lg:pb-20"
        initial="hidden"
        animate="visible"
        variants={variants1}
      >
        Steganography
      </motion.p>
      <div className="flex flex-col xl:flex-row">
        <div className="lg:w-5/6 w-full xs:w-5/6 lg:mt-2">
          <motion.div
            initial="hidden"
            animate="visible"
            variants={variants2}
            className="shadow-2xl rounded-lg lg:w-3/4 w-full font-normal mt-16 py-4 px-7 text-xl border-l-4 border-green-400 mb-7"
          >
            This mini project is done using Python Image Library (PIL). It takes
            a text input from a file and encodes it onto a PNG image. For every
            character in the secret message, the character's ASCII value is
            taken and converted into a 8-bit binary. Accordingly it manipulates
            the image’s RGB value and generates an encoded image. Similarly, for
            decoding the encoded image, you must enter the filename of the
            encoded image and then the decoded message will be displayed on the
            screen.
          </motion.div>
          <motion.div
            className="my-10 lg:ml-0 ml-10"
            initial="hidden"
            animate="visible"
            variants={variants4}
          >
            <a
              href="https://github.com/bimbisara13/Steganography"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button class="lg:mr-16 mr-8 w-40 bg-transparent hover:bg-gray-50 text-white-700 font-normal hover:text-gray-900 py-2 px-1 border border-white-500 hover:border-transparent rounded">
                See Project &#8594;
              </button>
            </a>
          </motion.div>
        </div>
        <motion.div
          className="mx-auto"
          initial="hidden"
          animate="visible"
          variants={variants3}
        >
          <img id="developer" style={devSize} src={steganography} alt="" />
        </motion.div>
      </div>
    </div>
  );
}

export default p3;
