import React, { useEffect } from "react";
import Aos from "aos";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";
import "../styles/index.css";
import "../styles/projects.css";
import projects from "../assets/projects.svg";
import faceid from "../assets/faceid.png";
import fullstack from "../assets/fullstack.png";
import encrypted from "../assets/encrypted.png";
import global from "../assets/global.png";

function Projects() {
  const devSize = {
    height: "60vh",
    width: "auto",
  };

  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  return (
    <div
      id="projects"
      className="lg:px-32 my-auto min-h-screen flex flex-col justify-center lg:ml-15"
    >
      <p
        className="titles mx-auto my-7"
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="200"
      >
        <span className="title">Projects</span>
      </p>
      <div className="flex flex-col xl:flex-row">
        <div
          className="lg:w-1/2 md:w-3/4 mx-auto lg:w-3/4 xs:w-full lg:mt-20 sm:my-0"
          data-aos="zoom-in-right"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          <img src={projects} style={devSize} alt="" />
        </div>
        <div className="lg:w-full lg:ml-20 my-auto">
        <Link to="/face-recognition" target="_blank">
          <div
            data-aos="fade-left"
            data-aos-duration="700"
            data-aos--delay="300"
          >
            <div className="shadow-2xl rounded-lg font-normal mt-10 py-4 project-div divBg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
              <img
                src={faceid}
                className="float-right mr-4 my-auto"
                style={{ height: 64, width: 64 }}
                alt=""
              />
              <p className="text-gray-500 ml-4 text-lg">April ’20</p>
              <p className="ml-4 text-xl mt-1">
                Facial Recognition using Image Processing
              </p>
            </div>
          </div>
          </Link>

          <Link to="/expense-approval-system" target="_blank">
          <div
            data-aos="fade-left"
            data-aos-duration="700"
            data-aos-delay="400"
          >
            <div className="shadow-2xl rounded-lg font-normal mt-10 py-4 project-div divBg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
              <img
                src={fullstack}
                className="float-right mr-4 my-auto"
                style={{ height: 64, width: 64 }}
                alt=""
              />
              <p className="text-gray-500 ml-4 text-lg">July ’20</p>
              <p className="ml-4 text-xl mt-1">
                Web-based Expense Approval System
              </p>
            </div>
          </div>
          </Link>

          <Link to="/steganography" target="_blank">
          <div
            data-aos="fade-left"
            data-aos-duration="700"
            data-aos-delay="500"
          >
            <div className="shadow-2xl rounded-lg font-normal mt-10 py-4 project-div divBg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
              <img
                src={encrypted}
                className="float-right mr-4 my-auto"
                style={{ height: 64, width: 64 }}
                alt=""
              />
              <p className="text-gray-500 ml-4 text-lg">December ’20</p>
              <p className="ml-4 text-xl mt-1">Steganography</p>
            </div>
          </div>
          </Link>

          <Link to="/consultus" target="_blank">
          <div
            data-aos="fade-left"
            data-aos-duration="700"
            data-aos-delay="600"
          >
            <div className="shadow-2xl rounded-lg font-normal my-10 py-4 project-div divBg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
              <img
                src={global}
                className="float-right mr-4 my-auto"
                style={{ height: 64, width: 64 }}
                alt=""
              />
              <p className="text-gray-500 ml-4 text-lg">January ’21</p>
              <p className="ml-4 text-xl mt-1">Website for CONSULTUS</p>
            </div>
          </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Projects;
