import React from "react";
import Particles from "react-particles-js";
import { motion } from "framer-motion";
import particlesConfig from "./particles-config";
import "../styles/index.css";
import "../styles/home.css";

function Home() {

  function gotoContact() {
    var element = document.getElementById("contact");
    element.scrollIntoView();
  }

  const variants1 = {
    hidden: {
      opacity: 0,
      y: "-100vw",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: "70",
        delay: 0.5,
        duration: 1.5,
      },
    },
  };

  const variants2 = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 1.2,
        duration: 2,
      },
    },
  };

  const variants3 = {
    hidden: {
      opacity: 0,
      x: "-100vw",
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 1.5,
        duration: 1.5,
      },
    },
  };

  const variants4 = {
    hidden: {
      opacity: 0,
      y: "100vw",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 2.8,
        duration: 1,
      },
    },
  };

  const particlesJs = {
    position: "absolute",
    width: "100%",
    zIndex: -10,
  };

  return (
    <div
      id="home"
      className="h-screen pl-4 lg:px-32 my-auto flex flex-col justify-center lg:pl-20"
    >
      <motion.div
        initial="hidden"
        animate="visible"
        variants={variants2}
        style={particlesJs}
      >
        <Particles className="particles" params={particlesConfig} />
      </motion.div>
      <div>
        <motion.p initial="hidden" animate="visible" variants={variants1}>
          Hi, <br />I am <span style={{ color: "#1541DE" }}>Bimbisara</span>
        </motion.p>
        <motion.h1
          id="homeInfo"
          className="font-light italic"
          initial="hidden"
          animate="visible"
          variants={variants2}
        >
          I can help you with your software development projects.
        </motion.h1>
        <motion.h2
          style={{ marginTop: "1em" }}
          initial="hidden"
          animate="visible"
          variants={variants3}
          id="homeInfo1"
          className="font-light"
        >
          Have a project in mind?
        </motion.h2>
          <motion.button
            style={{ marginTop: "1em" }}
            className="homeBtn"
            type="button"
            initial="hidden"
            animate="visible"
            variants={variants4}
            onClick={gotoContact}
          >
            Let's Talk!
          </motion.button>
      </div>
    </div>
  );
}

export default Home;
