import React, { useState } from "react";
import SubmitModal from "./SubmitModal";
import { TextField } from "@material-ui/core";
import "../styles/contact.css";


function Form() {

  function setToFalse(){
    setOpen(false);
  }

  const [open, setOpen] = useState(false);

  const [data, setData] = useState({
    Name: "",
    Email: "",
    Message: ""
  });

  const {Name, Email, Message} = data;

  const handleChange = e => {
    setData({...data, [e.target.name]: e.target.value});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://v1.nocodeapi.com/bimbisara13/google_sheets/aOCLPeuVGrEqGAtK?tabId=MySubmissions", 
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify([
            [Name, Email, Message, new Date().toLocaleDateString()],
          ]),
      });

      await response.json();
      setData({...data, Name: "", Email: "", Message: ""});
      setOpen(true);
    }
    catch(err){
      console.log("Error");
    }
  };

  return (
    <form className="flex flex-col mx-6 my-10"  onSubmit={handleSubmit}>
      <p id="formHeading">Want to get in touch? I'd love to hear from you</p>
      <h1>&nbsp;</h1>
      <TextField label="Name" type="text" color="primary" name="Name" autoComplete="off" value={Name} onChange={handleChange} required />
      <h1>&nbsp;</h1>
      <TextField label="Email" type="email" color="primary" name="Email" autoComplete="off" value={Email} onChange={handleChange} required />
      <h1>&nbsp;</h1>
      <TextField label="Message" type="text" color="primary" name="Message" autoComplete="off" value={Message} onChange={handleChange} required />
      <h1>&nbsp;</h1>
      <div className="flex flex-row mt-6">
        <button id="submitBtn" type="submit">
          Submit
        </button>
        <a style={{fontFamily: "Roboto"}} className="mt-3 ml-7 fa resume transform hover:-translate-y-1 hover:scale-105 transition duration-500 ease-in-out font-light flex flex-row" href="https://drive.google.com/file/d/1BStu1lyeXSVpzg__COq8LailD5AV-e9T/view?usp=sharing" target="_blank" rel="noopener noreferrer">Resume<img src={require("../assets/external-link.svg").default} style={{ height: "18px", width: "auto", marginLeft: "4px" }} alt="" /></a>
        <a style={{fontFamily: "Roboto"}} className="mt-3 ml-7 fa phone transform hover:-translate-y-1 hover:scale-105 transition duration-500 ease-in-out font-light flex flex-row " href={"tel:+91-82969-32512"} target="_blank" rel="noopener noreferrer">Phone <img src={require("../assets/phone-call.svg").default}  style={{ height: "18px", width: "auto", marginLeft: "4px" }}  alt="" /></a>
      </div>
      { open && <SubmitModal setToFalse={setToFalse}/>}
    </form>
  );
}

export default Form;
