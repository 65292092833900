import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "../styles/skills.css";
import "../styles/index.css";
import skills from "../assets/skills.svg";

function Skills() {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  return (
    <div
      id="skills"
      className="lg:px-32 my-auto min-h-screen flex flex-col"
      data-aos="zoom-in-up"
      data-aos-duration="1500"
      data-aos-delay="300"
    >
      <div
        className="mx-auto"
        data-aos="fade-down"
        data-aos-duration="1500"
        data-aos-delay="300"
      >
        <p className="titles my-7 flex flex-row xl:mb-9">
          <span className="text-white mt-5">Skills</span>
          <img className="ml-5 mr-9" src={skills} alt="" />
        </p>
      </div>
      <div className="flex flex-col xl:flex-row text-white">
        <div className="lg:w-1/2 w-full lg:mx-6">
          <div
            className="shadow-2xl rounded-lg w-full font-normal my-7 py-9 skills-section"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="500"
          >
            <p className="text-2xl flex justify-center">
              Web Design and Development
            </p>
            <div className="flex flex-row justify-center">
              <img
                className="mx-5 mt-5"
                src={require("../assets/Icons/html.png").default}
                style={{ height: 48, width: 48 }}
                alt=""
              />
              <img
                className="mx-5 mt-5"
                src={require("../assets/Icons/css.png").default}
                style={{ height: 48, width: 48 }}
                alt=""
              />
              <img
                className="mx-5 mt-5"
                src={require("../assets/Icons/sass.png").default}
                style={{ height: 48, width: 48 }}
                alt=""
              />
              <img
                className="mx-5 mt-5"
                src={require("../assets/Icons/javascript.png").default}
                style={{ height: 48, width: 48 }}
                alt=""
              />
              <img
                className="mx-5 mt-5"
                src={require("../assets/Icons/php.png").default}
                style={{ height: 48, width: 48 }}
                alt=""
              />
            </div>
            <p className="text-xl font-light mt-5 flex justify-center">
              ( HTML | CSS | Sass | JavaScript | PHP )
            </p>
          </div>

          <div
            className="shadow-2xl rounded-lg w-full mx-auto font-normal my-7 py-9 skills-section"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="500"
          >
            <p className="text-2xl flex justify-center">Web Frameworks</p>
            <div className="flex flex-row justify-center">
              <img
                className="mx-5 mt-5"
                src={require("../assets/Icons/react.png").default}
                style={{ height: 48, width: 48 }}
                alt=""
              />
              <img
                className="mx-5 mt-5"
                src={require("../assets/Icons/jquery.png").default}
                style={{ height: 48, width: 48 }}
                alt=""
              />
              <img
                className="mx-5 mt-5"
                src={require("../assets/Icons/bootstrap.png").default}
                style={{ height: 48, width: 48 }}
                alt=""
              />
              <img
                className="mx-5 mt-5"
                src={require("../assets/Icons/tailwind.png").default}
                style={{ height: 48, width: 48, borderRadius: "30px" }}
                alt=""
              />
            </div>
            <p className="text-xl font-light mt-5 flex justify-center">
              ( React | jQuery | Bootstrap | Tailwind CSS )
            </p>
          </div>
        </div>
        <div className="lg:w-1/2 w-full lg:mx-6">
          <div
            className="shadow-2xl rounded-lg w-full mx-auto font-normal my-7 py-9 skills-section"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="500"
          >
            <p className="text-2xl flex justify-center">Database Management</p>
            <div className="flex flex-row justify-center">
              <img
                className="mx-5 mt-5"
                src={require("../assets/Icons/mysql.png").default}
                style={{ height: 48, width: 48 }}
                alt=""
              />
              <img
                className="mx-5 mt-5"
                src={require("../assets/Icons/mongodb.svg").default}
                style={{ height: 48, width: 48 }}
                alt=""
              />
              <img
                className="mx-5 mt-5"
                src={require("../assets/Icons/azure.png").default}
                style={{ height: 48, width: 48, borderRadius: "30px" }}
                alt=""
              />
              <img
                className="mx-5 mt-5"
                src={require("../assets/Icons/firebase.png").default}
                style={{ height: 48, width: 48, borderRadius: "30px" }}
                alt=""
              />
            </div>
            <p className="text-xl font-light mt-5 flex justify-center">
              ( MySQL | MongoDB | Azure | Firebase )
            </p>
          </div>

          <div
            className="shadow-2xl rounded-lg w-full mx-auto font-normal my-7 py-9 skills-section"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="500"
          >
            <p className="text-2xl flex justify-center">Other Languages</p>
            <div className="flex flex-row justify-center">
              <img
                className="mx-5 mt-5"
                src={require("../assets/Icons/c.png").default}
                style={{ height: 48, width: 48 }}
                alt=""
              />
              <img
                className="mx-5 mt-5"
                src={require("../assets/Icons/c++.png").default}
                style={{ height: 48, width: 48 }}
                alt=""
              />
              <img
                className="mx-5 mt-5"
                src={require("../assets/Icons/java.png").default}
                style={{ height: 48, width: 48, borderRadius: "30px" }}
                alt=""
              />
              <img
                className="mx-5 mt-5"
                src={require("../assets/Icons/python.png").default}
                style={{ height: 48, width: 48, borderRadius: "30px" }}
                alt=""
              />
            </div>
            <p className="text-xl font-light mt-5 flex justify-center">
              ( C | C++ | Java | Python3 )
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skills;
