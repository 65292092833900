import React from "react";
import "../styles/index.css";

function ScrollIndicator() {
  window.onscroll = function () {
    myFunction();
  };

  function myFunction() {
    var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    document.getElementById("myBar").style.width = scrolled + "%";
  }

  return (
    <div className="progress-container z-10">
      <div className="progress-bar" id="myBar"></div>
    </div>
  );
}

export default ScrollIndicator;
