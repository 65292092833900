import React from "react";
import "../styles/preLoader.scss";

function PreLoader() {
  return (
    <div id="container" className="h-screen my-auto flex flex-col justify-center mx-1/2">
      <div className="divider" aria-hidden="true"></div>
        <p className="loading-text" aria-label="Loading">
          <span className="letter" aria-hidden="true">L</span>
          <span className="letter ml-5" aria-hidden="true">o</span>
          <span className="letter ml-5" aria-hidden="true">a</span>
          <span className="letter ml-3 mr-2" aria-hidden="true">d</span>
          <span className="letter ml-1" aria-hidden="true">i</span>
          <span className="letter ml-5" aria-hidden="true">n</span>
          <span className="letter ml-5" aria-hidden="true">g</span>
        </p>
    </div>
  );
}

export default PreLoader;
